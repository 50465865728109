import atom from 'atom-js';

/**
 * React-router is hard to work with.
 * The only way to access router data is wait until it pass props to main view.
 * router data provider stores data from main view props.
 *
 * available props:
 *   history: Object
 *   location: Object
 *   params: Object
 *   route: Object
 *   routeParams: Object
 *   routes: Array[2]
 */

export default atom();
