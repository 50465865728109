/*
  ResizeObserver is used just in Webcam.js and it's not mission critical to have it 100% right on
  all old browsers. That's why I'm not including resize-observer-polyfill (70+KB) but using this
  micro implementation.

  Here resize is triggered on window.resize only
 */

if (typeof window !== 'undefined') {
  window.TSResizeObserver = window.ResizeObserver || class TSResizeObserver {
    constructor(resizeCallback) {
      this.resizeCallback = resizeCallback;
    }

    observe() {
      window.addEventListener(window, 'resize', this.resizeCallback);
    }

    disconnect() {
      window.removeEventListener(window, 'resize', this.resizeCallback);
      this.resizeCallback = null;
    }
  }
}
