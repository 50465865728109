import is from 'next-is';
import BaseComponent from 'components/BaseComponent';
import { scrollTo } from 'sf/helpers/domHelper';
import layout from 'models/layout';
import router from 'models/router';
import user from 'models/user';

let restrictedTimeout; // Notice: this timeout is shared between all pages.

const DEFAULT_LAYOUT_PROPS = {
  manualHeaderFloat: false,
};

export default class BasePage extends BaseComponent {
  isPageRestricted = false;

  /**
   * getComponentData is used for server side rendering.
   * It can set data to models before constructor is called.
   *
   * @param  {object} props  Props from router.
   * @return {object[Promise]}
   */
  getComponentData() {
    return Promise.resolve();
  }

  /**
   * other parts of application are informed if current page has setRestricted(true)
   * or not.
   * Timeout is just in case, setRestricted is called in other lifecycle methods.
   *
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  constructor(props) {
    super(props);
    clearTimeout(restrictedTimeout);
    const layoutProps = {
      ...DEFAULT_LAYOUT_PROPS,
      ...props.layoutProps,
    };
    layout.set({ layoutProps });
    restrictedTimeout = setTimeout(() => {
      if (!this.isDestroyed) {
        router.set('isPageRestricted', this.isPageRestricted);
      }
    }, 2000);

    setTimeout(() => {
      this.publish('updateHeaderTags', {
        title: this.title,
        description: this.description,
      });
    });

    if (is.browser() && !document.location.hash) {
      scrollTo(0);
    }
  }

  /*
  TODO: setRestricted(true) has "nice" side effect. If profile data isn't loaded
  it waits for user.isSignedIn, while renders null. In ideal world we should wait
  with render for all required props more explicit :)
  Like in ng: http://stackoverflow.com/questions/16617259#answer-16620145
  */
  setRestricted(isRestricted) {
    this.isPageRestricted = isRestricted;
    if (isRestricted && !user.get('isSignedIn')) {
      if (is.browser()) {
        const next = location.href.replace(location.origin, '');
        this.navigate(`LogIn.html#next=${encodeURIComponent(next)}`);
      } else {
        this.render = () => {
          return null;
        };
      }
    }
  }
}
