/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Icon from '../Icon';

export default class HelpIcon extends BaseComponent {
  className = 'ts-HelpIcon';

  static propTypes = {
    discription: PropTypes.string,
    // set: images in assets/icons/*
    // in case of '..' - main directory is used
    set: PropTypes.oneOf(['fa', 'io', 'ts', '..']),
    size: PropTypes.number,
    style: PropTypes.object,
    type: PropTypes.string.isRequired,
    color: PropTypes.string,
  };

  static defaultProps = {
    discription: '',
    color: null,
    external: false,
    href: null,
    set: 'fa',
    size: 16,
    style: {},
  };


  render(
    { size, type, color, description, set },
  ) {
    return (
      <div className={ this.rootcn`` }>
        <Icon
          className={ this.cn`__icon` }
          set={ set }
          type={ type }
          size={ size }
          color={ color }
        />
        <span className={ this.cn`__description` }>
          { description }
        </span>
      </div>
    );
  }
}
