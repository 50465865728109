/* global BRAND_NAME */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import BaseComponent from 'components/BaseComponent';

export default class CustomLogo extends BaseComponent {
  className = 'ts-CustomLogo';

  static propTypes = {
    appName: PropTypes.string,
    logoSrc: PropTypes.string,
  };

  static defaultProps = {
    appName: 'TrustStamp',
  };

  render() {
    return (
      <div className={ this.rootcn() }>
        <Link
          className={ this.cn`__logo-link` }
          to="/"
        >
          { this.props.logoSrc &&
            <img
              alt={ this.props.appName }
              className={ this.cn`__logo-image` }
              src={ this.props.logoSrc }
            />
          }
        </Link>
      </div>
    );
  }
}
