/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import model from './model';

export default class Icon extends BaseComponent {
  className = 'ts-Icon';

  static model = model;

  static propTypes = {
    children: PropTypes.string,
    color: PropTypes.string,
    external: PropTypes.bool,
    href: PropTypes.string,
    // set: images in assets/icons/*
    // in case of '..' - main directory is used
    set: PropTypes.oneOf(['fa', 'io', 'ts', '..']),
    size: PropTypes.number,
    style: PropTypes.object,
    type: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: '',
    color: null,
    external: false,
    href: null,
    set: 'fa',
    size: 16,
    style: {},
  };

  state = {
    icon: model.get(`${this.props.set}/${this.props.type}`) || '',
  };

  componentDidMount() {
    this.updateIcon(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this.updateIcon(nextProps);
  }

  updateIcon({ type, set }) {
    model.fetchIcon(type, set, (icon) => {
      if (!this.isDestroyed) {
        this.setState({
          icon,
        });
      }
    });
  }

  render(
    { size, type, color, href, external, style, children },
    { icon }
  ) {
    const classNames = {
      [`--${type}`]: true,
    };
    const className = this.rootcn(classNames);
    const defaultStyle = {
      color: color,
      height: `${size}px`,
      lineHeight: `${size}px`,
      width: `${size}px`,
    };

    if (href) {
      return (
        <a
          className={ className }
          dangerouslySetInnerHTML={ { __html: icon } }
          href={ href }
          rel={ external ? 'noreferrer' : '' }
          style={ { ...defaultStyle, ...style } }
          target={ external ? '_blank' : '' }
          title={ children }
        />
      );
    }
    return (
      <span
        { ...this.pickProps() }
        className={ className }
        style={ { ...defaultStyle, ...style } }
        title={ children }
        dangerouslySetInnerHTML={ { __html: icon } }
        role="img"
      />
    );
  }
}
